<template>
  <div data-app>
    <div class="row justify-content-end mb-3">
      <div class="col-md-4 text-right">
        <button class="btn btn-primary mr-3" @click.prevent="loadTerdampak">
          <i class="flaticon2-refresh-button"></i> Load Item
        </button>

        <template v-if="isReady">
          <template v-if="isLoadUpdate">
            <button class="btn btn-primary mr-3">
              <b-spinner
                variant="light"
                style="width: 1rem; height: 1rem;"
                label="Spinning"
              ></b-spinner>
            </button>
          </template>
        </template>

        <template>
          <button
            class="text-white font-weight-bold mr-3 btn btn-success"
            v-b-modal.modal-xl
          >
            <i class="flaticon-add"></i> Add Data
          </button>
        </template>
      </div>
      <div class="col-md-4 py-2 col-sm-12">
        <div class="input-group mt-2">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i class="flaticon2-search-1"></i>
            </span>
          </div>

          <input
            type="text"
            v-model="searchData"
            class="form-control searchInput"
            placeholder="Search"
          />
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <complete-table
        :loading="isLoad"
        :data="this.$store.getters.getterDesignTerdampak"
      >
        <template #header>
          <th style="min-width: 100px;">Numbering</th>
          <th style="min-width: 100px;">Title</th>
          <th style="min-width: 100px;">Type</th>
          <th style="min-width: 100px;">Category</th>
          <th style="min-width: 100px;">Scope</th>
          <th style="min-width: 100px;">Station</th>
          <th style="min-width: 100px;">Plan Date</th>
          <th style="min-width: 200px;" class="text-center">Action</th>
        </template>
        <!-- <template v-if="this.$store.getters.getterDesignTerdampak.length == 0">
          <tbody>
            <tr v-if="isLoad">
              <td colspan="8" class="text-center">
                <b-spinner variant="primary" label="Spinning"></b-spinner>
              </td>
            </tr>
            <tr v-else>
              <td colspan="8" class="text-center">
                <div class="text-center">
                  <i class="flaticon2-open-box text-secondary icon-3x"></i>

                  <h4>
                    Data Empty.
                  </h4>
                </div>
              </td>
            </tr>
          </tbody>
        </template> -->

        <template #defaultBody="{item, i}">
          <tr>
            <td>{{ item.dld ? item.dld.numbering : "-" }}</td>
            <td>{{ item.dld ? item.dld.title : "-" }}</td>
            <td>
              {{ item.dld ? (item.dld.type ? "-" : item.dld.type.name) : "-" }}
            </td>
            <td>
              {{ item.dld ? item.dld.category_code : "-" }}
            </td>
            <td>
              {{
                item.dld
                  ? item.dld.scope == null
                    ? "-"
                    : item.dld.scope.name
                  : "-"
              }}
            </td>
            <td>
              {{
                item.dld
                  ? item.dld.station == null
                    ? "-"
                    : item.dld.station.name
                  : "-"
              }}
            </td>
            <td>
              <template>
                <div v-bind:class="'d-none edit-form-terdampak-' + i">
                  <b-input-group>
                    <input
                      class="form-control"
                      type="text"
                      v-model="add.date"
                      id="example-date-input"
                      placeholder="YYYY-MM-DD"
                    />
                    <b-input-group-append>
                      <b-form-datepicker
                        button-only
                        right
                        reset-button
                        reset-value=""
                        v-model="add.date"
                      ></b-form-datepicker>
                    </b-input-group-append>
                  </b-input-group>
                </div>

                <div v-bind:class="'edit-text-terdampak-' + i">
                  {{
                    item.dld
                      ?
                      moment(item.dld.planning_date).isValid() ? 
                      moment(item.dld.planning_date).format("DD-MM-YYYY") : ""
                      : "-"
                  }}
                </div>
              </template>
            </td>
            <td class="text-center">
              <template>
                <div v-bind:id="'action-terdampak-' + i">
                  <a
                    class="btn btn-light-success font-weight-bolder font-size-sm"
                    @click.prevent="editTerdampak(item, i)"
                  >
                    <i class="flaticon-edit"></i>
                  </a>

                  <a
                    class="btn btn-light-danger font-weight-bolder font-size-sm ml-2"
                    @click="openDialogDelete(item)"
                  >
                    <i class="flaticon-delete"></i>
                  </a>
                </div>
                <div class="d-none" v-bind:id="'action-cancel-terdampak-' + i">
                  <a
                    class="btn btn-light-danger font-weight-bolder font-size-sm"
                    @click.prevent="cancelTerdampak(i)"
                  >
                    <i class="flaticon2-cross"></i>
                  </a>
                </div>
              </template>
            </td>
          </tr>
        </template>
      </complete-table>
    </div>

    <div class="d-flex justify-content-end">
      <template v-if="!isLoadUpdate && isReady">
        <button class="btn btn-success mr-3" @click.prevent="save">
          <i class="flaticon2-check-mark"></i> Save Change Management Item
        </button>
      </template>
    </div>

    <!-- modal -->
    <b-modal
      id="modal-xl"
      :no-close-on-backdrop="true"
      footer-class="d-none"
      scrollable
      size="xl"
      title="Impacted Design"
    >
      <add-design-terdampak></add-design-terdampak>
    </b-modal>

    <v-dialog
      v-model="dialog"
      transition="dialog-top-transition"
      max-width="400"
    >
      <v-card>
        <v-card-title class="headline">Warning</v-card-title>

        <v-card-text>
          Are You Sure Want To Delete Data
          <b>
            {{
              this.currentDelete == null ? "" : "'" + this.currentDelete + "'"
            }}
          </b>
          ?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="deleteDesign()">
            Delete
          </v-btn>

          <v-btn color="green darken-1" text @click="closeDialog">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AddDesignTerdampak from "./AddDesignTerdampak";
import {
  GET_DESIGN_TERDAMPAK,
  UPDATE_DESIGN_TERDAMPAK,
  DELETE_DESIGN_TERDAMPAK,
  RESET_DESIGN_TERDAMPAK,
} from "@/core/services/store/change-management.module";
import { deleteModal } from "../../../../core/helper/toast.helper";
import CompleteTable from "../../../content/widgets/advance-table/CompleteTable.vue";

export default {
  name: "DesignTerdampak",
  components: {
    AddDesignTerdampak,
    CompleteTable,
  },
  data() {
    return {
      data: {
        design: [
          {
            numbering: "GEN-F08-002100",
            title: "PDPS",
            type: "Document",
            category: "External-T",
            scope: "HMI",
            station: "TAN",
          },
          {
            numbering: "GEN-F08-002100",
            title: "PDPS",
            type: "Document",
            category: "External-T",
            scope: "HMI",
            station: "TAN",
          },
        ],
      },
      add: {
        date: "",
        idDld: "",
        idChange: "",
      },
      isLoad: false,
      isReady: false,
      isAdd: false,
      isInput: false,
      isLoadUpdate: false,
      dialog: false,
      isModalDelete: true,
      currentDelete: null,
      currentDeleteId: null,
      currentEdit: null,
      searchData: "",
    };
  },
  methods: {
    editTerdampak(item, x) {
      if (this.isInput == true) {
        this.$bvToast.toast("please finish edit data", {
          title: "Warning",
          variant: "danger",
          solid: true,
        });
      } else {
        var input = document.getElementsByClassName("edit-form-terdampak-" + x);
        var text = document.getElementsByClassName("edit-text-terdampak-" + x);
        var action = document.getElementById("action-terdampak-" + x);
        var cancel = document.getElementById("action-cancel-terdampak-" + x);

        for (var i = 0; i < input.length; i++) {
          input[i].classList.remove("d-none");
          text[i].classList.add("d-none");
        }

        var idDld = item.dld.id;
        var idChange = item.id;

        action.classList.add("d-none");
        cancel.classList.remove("d-none");
        this.isReady = true;
        this.add.idDld = idDld;
        this.add.idChange = idChange;
        this.currentEdit = x;
        this.isInput = true;
      }
    },
    cancelTerdampak(id) {
      var input = document.getElementsByClassName("edit-form-terdampak-" + id);
      var text = document.getElementsByClassName("edit-text-terdampak-" + id);
      var action = document.getElementById("action-terdampak-" + id);
      var cancel = document.getElementById("action-cancel-terdampak-" + id);

      for (var i = 0; i < input.length; i++) {
        input[i].classList.add("d-none");
        text[i].classList.remove("d-none");
      }

      action.classList.remove("d-none");
      cancel.classList.add("d-none");
      this.isReady = false;
      this.currentEdit = null;
      this.add.idChange = "";
      this.add.idDld = "";
      this.isInput = false;
    },
    async loadTerdampak() {
      this.isLoad = true;
      await this.$store.dispatch(GET_DESIGN_TERDAMPAK, {
        id: this.$route.params.id_change_management,
      });
      this.isLoad = false;
      this.isAdd = true;
    },
    async save() {
      if (this.add.date == "") {
        this.$bvToast.toast("Tanggal harus diisi.", {
          title: "Warning",
          variant: "danger",
          solid: true,
        });
      } else {
        var date = this.add.date;
        var split = date.split("-");
        var fix = split[2] + "-" + split[1] + "-" + split[0];
        var id = this.add.idChange;
        var idDld = this.add.idDld;
        var send = [
          {
            id: id,
            dld: {
              id: idDld,
              planning_date: fix,
            },
          },
        ];

        var data = { dld: send };
        this.isLoadUpdate = true;
        await this.$store.dispatch(UPDATE_DESIGN_TERDAMPAK, {
          send: data,
          id: this.$route.params.id_change_management,
        });

        this.isLoadUpdate = false;

        // load
        this.loadTerdampak();
        this.cancelTerdampak(this.currentEdit);

        if (this.$store.getters.getterUpdateDesignTerdampak.length > 0) {
          this.$bvToast.toast("Update success.", {
            title: "Success",
            variant: "success",
            solid: true,
          });
        } else {
          this.$bvToast.toast("Something Wrong.", {
            title: "Warning",
            variant: "danger",
            solid: true,
          });
        }
      }
    },
    openDialogDelete(item) {
      // this.dialog = true;
      // this.isModalDelete = false;
      this.currentDelete = item.dld.title;
      this.currentDeleteId = item.id;
      this.deleteDesign();
    },
    closeDialog() {
      this.dialog = false;
      this.isModalDelete = true;
      this.currentDelete = null;
      this.currentDeleteId = null;
    },
    async deleteDesign() {
      const deleteFunction = async () => {
        this.dialog = false;
        this.isModalDelete = true;
        var id = this.currentDeleteId;
        await this.$store.dispatch(DELETE_DESIGN_TERDAMPAK, { id: id });
        this.$bvToast.toast("Data Deleted Successfully", {
          title: "Success",
          variant: "success",
          solid: true,
        });
        this.loadTerdampak();
      };

      deleteModal(deleteFunction);
    },
  },
  mounted() {
    this.$root.$on("bv::modal::show", () => {
      this.$store.dispatch(RESET_DESIGN_TERDAMPAK);
    });
  },
  computed: {
    dataWithFilter() {
      return this.$store.getters.getterDesignTerdampak.filter(
        (items) =>
          items.dld.numbering
            .toLowerCase()
            .includes(this.searchData.toLowerCase()) ||
          items.dld.title
            .toLowerCase()
            .includes(this.searchData.toLowerCase()) ||
          (items.dld.type == null
            ? false
            : items.dld.type.name
                .toLowerCase()
                .includes(this.searchData.toLowerCase())) ||
          (items.dld.category_code == null
            ? false
            : items.dld.category_code
                .toLowerCase()
                .includes(this.searchData.toLowerCase())) ||
          (items.dld.planning_date == null
            ? false
            : items.dld.planning_date
                .toLowerCase()
                .includes(this.searchData.toLowerCase())) ||
          (items.dld.station == null
            ? false
            : items.dld.station.name
                .toLowerCase()
                .includes(this.searchData.toLowerCase())) ||
          (items.dld.scope == null
            ? false
            : items.dld.scope.name
                .toLowerCase()
                .includes(this.searchData.toLowerCase()))
      );
    },
  },
};
</script>
