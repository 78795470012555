<template>
  <div>
    <div class="container-fluid p-0">
      <div class="row justify-content-end">
          <div class="col-md-2 col-sm-12 text-right">
            <button class="btn btn-primary" @click.prevent="load">
              Load Design
            </button>
          </div>
          <div class="col-md-4 col-sm-12">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="flaticon2-search-1"></i>
                </span>
              </div>
    
              <input
                type="text"
                v-model="searchData"
                class="form-control searchInput"
                placeholder="Search"
              />
            </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="table-responsive">
        <table
          class="table table-head-custom table-vertical-center table-head-bg table-bordered"
        >
          <thead>
            <tr>
              <th></th>
              <th>Numbering</th>
              <th>Title</th>
              <th>Type</th>
              <th>Part</th>
              <th>Scope</th>
              <th>Station</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody v-if="dataWithFilter.length == 0">
            <template v-if="isLoading">
              <tr>
                <td colspan="8">
                  <div class="text-center">
                    <i class="flaticon2-open-box text-secondary icon-3x"></i>

                    <h4>
                      Data Empty.
                    </h4>
                  </div>
                </td>
              </tr>
            </template>

            <template v-else>
              <tr>
                <td class="text-center" colspan="8">
                  <b-spinner variant="primary" label="Spinning"></b-spinner>
                </td>
              </tr>
            </template>
          </tbody>
          <tbody v-else>
            <template
              v-for="(item, i) in dataWithFilter"
            >
              <tr v-bind:key="i">
                <td>
                  <template>
                    <!-- <div v-if="item.outstanding == 1">
                      <b-form-checkbox
                        v-bind:id="'check-drawing-' + i"
                        checked="true"
                        name="checkbox-1"
                        disabled
                      >
                      </b-form-checkbox>
                    </div> -->
                    <!-- <div v-else> -->
                      <div >
                      <b-form-checkbox
                        v-bind:id="'check-drawing-' + item.id"
                        v-model="outstanding"
                        name="checkbox-1"
                        :value="
                          item.planning_date == null
                            ? item.id
                            : item.id + '/' + item.planning_date
                        "
                      >
                      </b-form-checkbox>
                    </div>
                  </template>
                </td>
                <td>{{ item.numbering }}</td>
                <td>{{ item.title }}</td>
                <td>{{ item.type == null ? "-" : item.type.name }}</td>
                <td>{{ item.category == null ? "-" : item.category.name }}</td>
                <td>{{ item.scope == null ? "-" : item.scope.name }}</td>
                <td>{{ item.station == null ? "-" : item.station.name }}</td>
                <td>
                  <template>
                    <div v-if="item.isAdd != null">
                      <a href="" class="text-danger">
                        <i class="flaticon-delete"></i>
                      </a>
                    </div>
                  </template>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="text-right mt-3">
          <div v-if="outstanding.length == 0">
            <button class="btn btn-success" disabled>Save</button>
          </div>
          <div v-else>
            <button class="btn btn-success" @click.prevent="save()">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  GET_DLD_CHANGE,
  POST_DESIGN_TERDAMPAK,
  GET_DESIGN_TERDAMPAK
} from "@/core/services/store/change-management.module";

export default {
  name: "Drawing",
  data() {
    return {
      data: {
        design: [
          {
            numbering: "GEN-008-002-100",
            title: "PDPS",
            type: "Drawing",
            field: "Eksternal-T",
            scope: "HMI",
            station: "GEN",
            isAdd: "1"
          },
          {
            numbering: "TAN-008-110-100",
            title: "PDPP",
            type: "Drawing",
            field: "Eksternal-T",
            scope: "HMI",
            station: "TAN",
            isAdd: null
          }
        ]
      },
      searchData: "",
      datas: [],
      outstanding: [],
      isLoading: true
    };
  },
  methods: {
    async load() {
      var code = this.$store.getters.getterDetailChangeManagement.project.code;
      var type = "DRW";

      this.isLoading = false;
      await this.$store.dispatch(GET_DLD_CHANGE, { type: type, code: code });
      this.datas = this.$store.getters.getterDldChange.dld.map(y => {
        if (y.station == null || y.station == undefined) {
          y.station = {
            code: "-",
            name: "-"
          };
        }
        if (y.type == null || y.type == undefined) {
          y.type = {
            code: "-",
            name: "-"
          };
        }

        if (y.scope == null || y.scope == undefined) {
          y.scope = {
            code: "-",
            name: "-"
          };
        }
        return y;
      });
      this.isLoading = true;
    },
    async save() {
      var send = this.outstanding;
      var newSend = [];

      for (var i = 0; i < send.length; i++) {
        if (Number.isInteger(send[i]) == false) {
          var split = send[i].split("/");
          var splitDate = split[1].split("-");
          var planning_date =
            splitDate[2] + "-" + splitDate[1] + "-" + splitDate[0];
          newSend.push({
            id: null,
            dld: { id: split[0], planning_date: planning_date }
          });
        } else {
          newSend.push({
            id: null,
            dld: { id: send[i], planning_date: null }
          });
        }
      }

      if (newSend.length > 0) {
        this.doSave = true;
        await this.$store.dispatch(POST_DESIGN_TERDAMPAK, {
          id: this.$route.params.id_change_management,
          send: newSend
        });
        this.doSave = false;

        var result = this.$store.getters.getterResPostDesignTerdampak;
        if (Array.isArray(result)) {
          if (this.$store.getters.getterResPostDesignTerdampak.length == 0) {
            this.$bvToast.toast("Failed Save Data", {
              title: "Warning",
              variant: "danger",
              solid: true
            });
          } else {
            this.$bvToast.toast("Data Saved Successfully", {
              title: "Success",
              variant: "success",
              solid: true
            });
            await this.$store.dispatch(GET_DESIGN_TERDAMPAK, {
              id: this.$route.params.id_change_management
            });
            this.$bvModal.hide("modal-xl");
            document.querySelectorAll('.searchInput').forEach(x => { x.value = ""; x.dispatchEvent(new Event('input', { 'bubbles': true })) });
            this.load();
          }
        } else {
          this.$bvToast.toast(result.msg, {
            title: "Warning",
            variant: "danger",
            solid: true
          });
        }
      } else {
        this.$bvToast.toast("Planning Date Can't Be Empty", {
          title: "Warning",
          variant: "danger",
          solid: true
        });
      }
    }
  },
  computed: {
    currentSave() {
      return this.status;
    },
    dataWithFilter() {
      return this.datas.filter(x => 
          x.numbering
            .toLowerCase()
            .includes(this.searchData.toLowerCase()) ||
          x.title.toLowerCase().includes(this.searchData.toLowerCase()) ||
          x.station.name
            .toLowerCase()
            .includes(this.searchData.toLowerCase()) ||
          x.type.name
            .toLowerCase()
            .includes(this.searchData.toLowerCase()) ||
          x.scope.name
            .toLowerCase()
            .includes(this.searchData.toLowerCase())
      );
    }
  },
  watch: {}
};
</script>
