<template>
  <div>
    <div class="text-right mb-3">
      <button class="btn btn-primary mr-3" @click.prevent="load">
        <i class="flaticon2-refresh-button"></i> Response
      </button>

      <template v-if="isAdd">
        <template v-if="loadingSave">
          <button class="btn btn-primary mr-3">
            <b-spinner
              variant="light"
              style="height: 1rem; width: 1rem;"
              label="Spinning"
            ></b-spinner>
          </button>
        </template>
      </template>

      <template v-if="activeEdit">
        <button
          class="text-white font-weight-bold mr-3 btn btn-success"
          @click.prevent="showErrorAdd"
        >
          <i class="flaticon-add"></i> Add Data
        </button>
      </template>

      <template v-else>
        <button
          class="text-white font-weight-bold mr-3 btn btn-success"
          @click.prevent="addRowResponse"
        >
          <i class="flaticon-add"></i> Add Data
        </button>
      </template>
    </div>
    <div class="table-responsive">
      <table
        class="table table-head-custom table-vertical-center table-head-bg table-borderless"
      >
        <thead>
          <tr>
            <th style="min-width: 100px;">Scope Category</th>
            <th style="min-width: 100px;">Response Date</th>
            <th style="min-width: 100px;">Status</th>
            <th style="min-width: 100px;">Action</th>
          </tr>
        </thead>
        <template v-if="datas.length == 0 && isEmpty == true">
          <tbody>
            <template v-if="isLoading">
              <tr>
                <td colspan="4" class="text-center">
                  <b-spinner variant="primary" label="Spinning"></b-spinner>
                </td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td colspan="4">
                  <div class="text-center">
                    <i class="flaticon2-open-box text-secondary icon-3x"></i>

                    <h4>
                      Data Empty.
                    </h4>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </template>
        <template v-if="isLoading && datas.length >= 1 && datas.length < 5">
          <div class="text-center" style="height: 15rem;"></div>
          <tbody>
            <tr v-for="(item, i) in datas" v-bind:key="i">
              <td>
                <span v-if="item.scope_category != null">{{
                  item.scope_category.name
                }}</span>
              </td>
              <td>
                <template>
                  <div v-bind:class="'response-chief-text-' + i">
                    {{
                      moment(item.date).isValid()
                        ? moment(item.date).format("DD-MM-YYYY")
                        : ""
                    }}
                  </div>
                  <div v-bind:class="'d-none response-chief-form-' + i">
                    <b-input-group>
                      <input
                        class="form-control"
                        type="text"
                        v-model="edit.date"
                        id="example-date-input"
                        placeholder="YYYY-MM-DD"
                      />
                      <b-input-group-append>
                        <b-form-datepicker
                          button-only
                          right
                          reset-button
                          reset-value=""
                          :no-flip="true"
                          v-model="edit.date"
                        ></b-form-datepicker>
                      </b-input-group-append>
                    </b-input-group>
                  </div>
                </template>
              </td>
              <td>
                <template>
                  <div v-bind:class="'response-chief-text-' + i">
                    {{ item.status }}
                  </div>
                  <div v-bind:class="'d-none response-chief-form-' + i">
                    <b-form-select
                      class="form-control"
                      :options="chief.options"
                      v-model="edit.status"
                    ></b-form-select>
                  </div>
                </template>
              </td>
              <td>
                <template>
                  <div v-bind:id="'response-chief-edit-' + i">
                    <a
                      class="btn btn-light-success"
                      @click.prevent="editResponseChief(i)"
                    >
                      <i class="flaticon-edit"></i>
                    </a>
                    <a
                      class="btn btn-light-danger font-weight-bolder font-size-sm ml-2"
                      @click="openDialogDelete(item)"
                    >
                      <i class="flaticon-delete"></i>
                    </a>
                  </div>
                  <div class="d-none" v-bind:id="'response-chief-save-' + i">
                    <a class="mr-3" @click.prevent="cancel(i)">
                      <i class="flaticon2-cross text-danger"></i>
                    </a>
                    <a
                      class="btn btn-primary"
                      @click.prevent="saveResponseChief(item, i)"
                    >
                      <template v-if="loadingUpdate">
                        <b-spinner
                          variant="light"
                          style="width: 1rem; height: 1rem;"
                          label="Spinning"
                        ></b-spinner>
                      </template>
                      <template v-else>
                        <i class="flaticon2-paperplane"></i>
                      </template>
                    </a>
                  </div>
                </template>
              </td>
            </tr>
          </tbody>
        </template>

        <template>
          <tbody
            v-bind:class="{
              'd-none': addResponse,
            }"
          >
            <tr>
              <td>
                <template>
                  <b-form-select v-model="add.scope" class="mb-3">
                    <b-form-select-option
                      v-for="(scope, i) in scopeCategories"
                      v-bind:key="i"
                      :value="scope.code"
                    >
                      {{ scope.name }}
                    </b-form-select-option>
                  </b-form-select>
                </template>
              </td>
              <td>
                <template>
                  <div>
                    <b-input-group>
                      <input
                        class="form-control"
                        v-model="add.date"
                        type="text"
                        placeholder="YYYY-MM-DD"
                      />
                      <b-input-group-append>
                        <b-form-datepicker
                          button-only
                          right
                          reset-button
                          reset-value=""
                          :no-flip="true"
                          v-model="add.date"
                        ></b-form-datepicker>
                      </b-input-group-append>
                    </b-input-group>
                  </div>
                </template>
              </td>
              <td>
                <template>
                  <div>
                    <b-form-select
                      class="form-control"
                      :options="chief.options"
                      v-model="add.status"
                    ></b-form-select>
                  </div>
                </template>
              </td>
              <td>
                <template>
                  <div>
                    <a class="mr-3" @click.prevent="cancelAddReponse()">
                      <i class="flaticon2-cross text-danger"></i>
                    </a>
                    <a
                      class="btn btn-primary"
                      @click.prevent="addResponseChief()"
                    >
                      <template v-if="loadingUpdate">
                        <b-spinner
                          variant="light"
                          style="width: 1rem; height: 1rem;"
                          label="Spinning"
                        ></b-spinner>
                      </template>
                      <template v-else>
                        <i class="flaticon2-paperplane"></i>
                      </template>
                    </a>
                  </div>
                </template>
              </td>
            </tr>
          </tbody>
        </template>
      </table>
    </div>
    <v-dialog
      v-model="dialog"
      transition="dialog-top-transition"
      max-width="400"
    >
      <v-card>
        <v-card-title class="headline">Warning</v-card-title>

        <v-card-text>
          Are You Sure Want To Delete Data
          <b>
            {{
              this.currentDelete == null ? "" : "'" + this.currentDelete + "'"
            }}
          </b>
          ?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="deleteResponseChief()">
            Delete
          </v-btn>

          <v-btn color="green darken-1" text @click="closeDialog">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  GET_STATUS_CHANGE,
  GET_DETAIL_CHANGE,
  GET_RES_CHIEF_CHANGE_MANAGEMENT,
  UPDATE_RES_CHIEF_CHANGE_MANAGEMENT,
  ADD_RES_CHIEF_CHANGE_MANAGEMENT,
  DELETE_RESPONSE_CHIEF,
} from "@/core/services/store/change-management.module";
import { GET_SCOPE_CATEGORY } from "@/core/services/store/scope-category.module";
import { mapState } from "vuex";
import { deleteModal } from "../../../../core/helper/toast.helper";
export default {
  name: "ResponseChief",
  props: ["project_code"],
  data() {
    return {
      edit: {
        date: "",
        status: "",
      },
      datas: [],
      chief: {
        options: { text: "loading ..." },
      },
      add: {
        scope: "",
        date: "",
        file: null,
      },
      selected: {
        response: "",
        list_management: "",
        scope: "",
      },
      uploadLoading: false,
      isLoading: false,
      isEdit: false,
      currentDelete: null,
      addResponse: true,
      isEmpty: true,
      isAdd: false,
      templateEmpty: false,
      activeEdit: false,
      dialogPic: false,
      isModalDeletePic: true,
      loadingSave: false,
      loadingUpdate: false,
      dialog: false,
      isModalDelete: true,
      currentDeleteId: null,
    };
  },
  async mounted() {
    this.$store.dispatch(GET_SCOPE_CATEGORY).then((x) => {
      var data = x.data;
      this.selected.scope = data.length > 0 ? data[0].code : "";
    });

    await this.$store.dispatch(GET_DETAIL_CHANGE, {
      id: this.$route.params.id_change_management,
    });
  },
  computed: {
    ...mapState({
      scopeCategories: (state) => state.scopeCategory.scopeCategories?.data,
    }),
    formattedScopeOptions() {
      return this.scopeCategories;
    },
  },
  methods: {
    async addRowResponse() {
      var check = this.$store.getters.getterResponseChangeManagement;
      this.chief.options = [];
      await this.$store.dispatch(GET_STATUS_CHANGE);

      var ops = [];
      var option = this.$store.getters.getterStatusChangeManagement;
      var select;
      for (var x = 0; x < option.length; x++) {
        select = {
          value: option[x].code,
          text: option[x].code + " - " + option[x].name,
        };
        ops.push(select);
      }

      this.chief.options = ops;
      if (check == "400" || check.length == 0) {
        this.isEmpty = false;
        this.templateEmpty = true;
        this.addResponse = false;
        this.isAdd = true;
      } else {
        this.isAdd = true;
        this.addResponse = false;
      }
    },

    cancelAddReponse() {
      this.addResponse = true;
      this.activeEdit = false;
      this.isAdd = false;
      this.add.status = null;
      this.add.scope = "";
      this.add.date = null;
      this.isEmpty = true;
    },
    async editResponseChief(id) {
      if (this.isEdit == false) {
        var input = document.getElementsByClassName(
          "response-chief-form-" + id
        );
        var text = document.getElementsByClassName("response-chief-text-" + id);

        for (var i = 0; i < input.length; i++) {
          input[i].classList.remove("d-none");
          text[i].classList.add("d-none");
        }

        //button
        var edit = document.getElementById("response-chief-edit-" + id);
        var save = document.getElementById("response-chief-save-" + id);

        edit.classList.add("d-none");
        save.classList.remove("d-none");

        //get data status
        this.isEdit = true;
        this.chief.options = [];
        await this.$store.dispatch(GET_STATUS_CHANGE);

        var ops = [];
        var option = this.$store.getters.getterStatusChangeManagement;
        var select;
        for (var x = 0; x < option.length; x++) {
          select = {
            value: option[x].code,
            text: option[x].code + " - " + option[x].name,
          };
          ops.push(select);
        }

        this.chief.options = ops;
      } else {
        this.$bvToast.toast("Please Finish Edit Data First", {
          title: "Warning",
          variant: "danger",
          solid: true,
        });
      }
    },

    cancel(id) {
      var input = document.getElementsByClassName("response-chief-form-" + id);
      var text = document.getElementsByClassName("response-chief-text-" + id);

      for (var i = 0; i < input.length; i++) {
        input[i].classList.add("d-none");
        text[i].classList.remove("d-none");
      }

      //button
      var edit = document.getElementById("response-chief-edit-" + id);
      var save = document.getElementById("response-chief-save-" + id);

      edit.classList.remove("d-none");
      save.classList.add("d-none");

      this.isEdit = false;
      this.edit.date = "";
      this.edit.status = "";
    },

    async load() {
      if (this.isEdit == false) {
        this.isLoading = true;
        await this.$store
          .dispatch(GET_RES_CHIEF_CHANGE_MANAGEMENT, {
            id: this.$route.params.id_change_management,
          })
          .then((x) => {
            console.log(x);
            if (x == undefined) {
              this.datas = [];
              this.isLoading = false;
            } else {
              this.datas = this.$store.getters.getterResChiefChangeManagement;
            }
          });
      } else {
        this.$bvToast.toast("Please Finish Edit Data First", {
          title: "Warning",
          variant: "danger",
          solid: true,
        });
      }
    },

    async saveResponseChief(item, i) {
      if (this.edit.date == "" || this.edit.status == "") {
        this.$bvToast.toast("All Field Can't Be Empty", {
          title: "Warning",
          variant: "danger",
          solid: true,
        });
      } else {
        var date = this.edit.date;
        var split = date.split("-");
        var fix = split[2] + "-" + split[1] + "-" + split[0];
        var send = [
          {
            id: item.id,
            date: fix,
            status: this.edit.status,
          },
        ];

        this.loadingUpdate = true;
        await this.$store.dispatch(UPDATE_RES_CHIEF_CHANGE_MANAGEMENT, {
          id: this.$route.params.id_change_management,
          send: send,
          project_code: this.project_code,
        });
        this.loadingUpdate = false;
        this.cancel(i);
        this.$bvToast.toast("Edit data saved successfully", {
          title: "Success",
          variant: "success",
          solid: true,
        });
        this.load();
        this.isEdit = false;
      }
    },
    async addResponseChief() {
      if (this.add.date == "" || this.add.status == "") {
        this.$bvToast.toast("All Field Can't Be Empty", {
          title: "Warning",
          variant: "danger",
          solid: true,
        });
      } else {
        var date = this.add.date;
        var split = date.split("-");
        var fix = split[0] + "-" + split[1] + "-" + split[2];
        var send = [
          {
            scope: this.add.scope,
            date: fix,
            status: this.add.status,
          },
        ];

        this.loadingUpdate = true;
        await this.$store.dispatch(ADD_RES_CHIEF_CHANGE_MANAGEMENT, {
          id: this.$route.params.id_change_management,
          send: send,
          project_code: this.project_code,
        });
        this.loadingUpdate = false;
        this.cancelAddReponse();
        this.$bvToast.toast("Add data saved successfully", {
          title: "Success",
          variant: "success",
          solid: true,
        });
        this.load();
        this.isEdit = false;
      }
    },
    openDialogDelete(item) {
      // this.currentDelete = item.dld.title;
      this.currentDeleteId = item.id;
      this.deleteResponse();
    },
    closeDialog() {
      this.dialog = false;
      this.isModalDelete = true;
      this.currentDelete = null;
      this.currentDeleteId = null;
    },
    async deleteResponse() {
      const deleteFunction = async () => {
        this.dialog = false;
        this.isModalDelete = true;
        var id = this.currentDeleteId;
        await this.$store.dispatch(DELETE_RESPONSE_CHIEF, { id: id });
        this.$bvToast.toast("Data Deleted Successfully", {
          title: "Success",
          variant: "success",
          solid: true,
        });
        this.load();
      };

      deleteModal(deleteFunction);
    },
  },
};
</script>
