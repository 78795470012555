<template>
  <div>
    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <div class="row mb-3 mt-5">
          <label class="col-sm-2 col-form-label">Project Code</label>
          <div class="col-sm-10">
            <b-form-input
              id="input-large"
              size="md"
              v-model="project_code"
              disabled
            ></b-form-input>
          </div>
        </div>

        <div class="row mb-3 mt-5">
          <label class="col-sm-2 col-form-label">Project Name</label>
          <div class="col-sm-10">
            <b-form-input
              id="input-large"
              size="md"
              v-model="project_name"
              disabled
            ></b-form-input>
          </div>
        </div>

        <div class="row mb-3 mt-5">
          <label class="col-sm-2 col-form-label">Status Project</label>
          <div class="col-sm-10">
            <b-form-input
              id="input-large"
              size="md"
              v-model="project_status"
              disabled
            ></b-form-input>
          </div>
        </div>
      </div>
    </div>

    <div>
      <b-card no-body>
        <b-tabs card v-model="tabs_change_management">
          <b-tab title="Change Management Info" active>
            <b-card-text>
              <!-- form -->
              <div class="row">
                <div class="col-6">
                  <label class="input-large">Change Management Number</label>

                  <b-form-input
                    id="input-large"
                    size="md"
                    v-model="cr_number"
                  ></b-form-input>
                </div>

                <div class="col-6">
                  <label class="input-large">
                    Date Received From Author
                  </label>
                  <b-input-group>
                    <input
                      class="form-control"
                      type="text"
                      v-model="date_arrived"
                      id="example-date-input"
                      placeholder="YYYY-MM-DD"
                    />
                    <b-input-group-append>
                      <b-form-datepicker
                        button-only
                        right
                        reset-button
                        reset-value=""
                        v-model="date_arrived"
                        :no-flip="true"
                      ></b-form-datepicker>
                    </b-input-group-append>
                  </b-input-group>
                </div>
              </div>

              <div class="row">
                <div class="col-6">
                  <label class="input-large">Title</label>

                  <b-form-input
                    id="input-large"
                    size="md"
                    v-model="title"
                  ></b-form-input>
                </div>

                <div class="col-6">
                  <label class="input-large">
                    Date Sent To Engineer
                  </label>
                  <b-input-group>
                    <input
                      class="form-control"
                      type="text"
                      v-model="date_send"
                      id="example-date-input"
                      placeholder="YYYY-MM-DD"
                    />
                    <b-input-group-append>
                      <b-form-datepicker
                        button-only
                        right
                        reset-button
                        reset-value=""
                        v-model="date_send"
                        :no-flip="true"
                      ></b-form-datepicker>
                    </b-input-group-append>
                  </b-input-group>
                </div>
              </div>

              <div class="row">
                <div class="col-6">
                  <label class="input-large">
                    CR Date
                  </label>
                  <b-input-group>
                    <input
                      class="form-control"
                      type="text"
                      v-model="date_cr"
                      id="example-date-input"
                      placeholder="YYYY-MM-DD"
                    />
                    <b-input-group-append>
                      <b-form-datepicker
                        button-only
                        right
                        reset-button
                        reset-value=""
                        v-model="date_cr"
                        :no-flip="true"
                      ></b-form-datepicker>
                    </b-input-group-append>
                  </b-input-group>
                </div>

                <div class="col-6">
                  <label class="input-large">
                    Type
                  </label>

                  <b-select
                    :options="formattedTypeOptions"
                    size="md"
                    v-model="type"
                  ></b-select>
                </div>

                <div class="col-md-6 py-2 col-sm-12">
                  <label for="input-large">File</label>

                  <b-form-group>
                    <b-form-input
                      id="input-large"
                      size="md"
                      v-model="file_name"
                      disabled
                    ></b-form-input>

                    <div class="mt-2">
                      <b-form-file
                        id="file-large"
                        size="md"
                        v-model="file"
                      ></b-form-file>
                    </div>
                  </b-form-group>
                </div>
              </div>

              <div class="text-right mb-4">
                <button
                  class="btn btn-success btn-md"
                  @click.prevent="saveInfo()"
                >
                  <div
                    v-bind:class="{
                      'd-none': saveInfoText,
                    }"
                  >
                    <i class="flaticon2-check-mark"></i> Save
                  </div>

                  <div
                    v-bind:class="{
                      'd-none': isLoading,
                    }"
                  >
                    <b-spinner
                      variant="light"
                      tyle="width: 1rem; height: 1rem;"
                      label="Spinning"
                    ></b-spinner>
                  </div>
                </button>
              </div>
            </b-card-text>
          </b-tab>

          <b-tab title="PIC">
            <b-card-text>
              <b-card-text>
                <pic :project_code="project_code" />
              </b-card-text>
            </b-card-text>
          </b-tab>

          <b-tab title="Impacted Design">
            <b-card-text>
              <design-terdampak :project_code="project_code" />
            </b-card-text>
          </b-tab>

          <b-tab title="Response">
            <b-card-text>
              <response :project_code="project_code" />
            </b-card-text>
          </b-tab>

          <b-tab title="Response to Chief">
            <b-card-text>
              <response-chief :project_code="project_code" />
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  GET_CHANGE_MANAGEMENT,
  GET_DETAIL_CHANGE,
  POST_INFO_CHANGE_MANAGEMENT,
  RESET_CHANGE_MANAGEMENT,
} from "@/core/services/store/change-management.module";
import DesignTerdampak from "./change-management/DesignTerdampak";
import Pic from "./change-management/Pic";
import Response from "./change-management/Response";
import ResponseChief from "./change-management/ResponseChief";
import { formattedOptions } from "../../../core/helper/array-validation.helper";
import ApiService from "../../../core/services/api.service";
import { showToast } from "../../../core/helper/toast.helper";
import moment from "moment";

export default {
  name: "change-management",
  components: {
    DesignTerdampak,
    Pic,
    Response,
    ResponseChief,
  },
  data() {
    return {
      dialog: false,
      cr_number: "",
      project_name: "",
      project_code: "",
      project_status: "",
      nickname: "",
      date_arrived: "",
      date_send: "",
      date_cr: "",
      title: "",
      file_name: null,
      type: null,
      options: {
        statuses: [
          { value: null, text: "Choose Status" },
          { value: "open", text: "Open" },
          { value: "close", text: "Close" },
        ],
        types: [],
      },
      saveInfoText: false,
      isLoading: true,
      tabs_change_management: 1,
      file: null,
    };
  },
  methods: {
    openDialog() {
      this.dialog = true;
    },

    async saveInfo() {
      var data = {
        send: {
          project: this.project_code,
          cr_number: this.cr_number,
          title: this.title,
          date: this.date_cr,
          date_received_from_chief: this.date_arrived,
          date_sent_to_enginering: this.date_send,
          type: this.type
            ? this.options.types.find((type) => type.code === this.type)
            : null,
        },
        id: this.$route.params.id_change_management,
      };

      this.isLoading = false;
      this.saveInfoText = true;

      await this.$store.dispatch(POST_INFO_CHANGE_MANAGEMENT, {
        payload: {
          ...data,
          file: this.file,
        },
      });
      this.isLoading = true;
      this.saveInfoText = false;
      this.$bvToast.toast("Data Saved Successfully.", {
        title: "Success",
        variant: "success",
        solid: true,
      });
    },

    getChangeManagementType() {
      ApiService.setHeader();

      ApiService.query("change/management/type")
        .then((response) => {
          this.options.types = response.data.data;
        })
        .catch(() => {
          showToast("Error", "Failed get change management type", "danger");
        });
    },
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Change Management" }]);

    this.$store.dispatch(GET_CHANGE_MANAGEMENT, { nickname: "", cr: "" });

    await this.$store.dispatch(GET_DETAIL_CHANGE, {
      id: this.$route.params.id_change_management,
    });
    this.project_code = this.$store.getters.getterDetailChangeManagement.project.code;
    this.cr_number = this.$store.getters.getterDetailChangeManagement.cr_number;
    this.project_name = this.$store.getters.getterDetailChangeManagement.project.name;
    this.project_status = this.$store.getters.getterDetailChangeManagement.project.status;
    this.title = this.$store.getters.getterDetailChangeManagement.title;
    this.file_name = this.$store.getters.getterDetailChangeManagement.file_name;
    this.date_arrived =
      this.$store.getters.getterDetailChangeManagement
        .date_received_from_chief == null
        ? ""
        : moment(
            this.$store.getters.getterDetailChangeManagement
              .date_received_from_chief
          ).format("DD-MM-YYYY");
    this.date_send =
      this.$store.getters.getterDetailChangeManagement
        .date_sent_to_enginering == null
        ? ""
        : moment(
            this.$store.getters.getterDetailChangeManagement
              .date_sent_to_enginering
          ).format("DD-MM-YYYY");
    this.date_cr =
      this.$store.getters.getterDetailChangeManagement.date == null
        ? ""
        : moment(this.$store.getters.getterDetailChangeManagement.date).format(
            "DD-MM-YYYY"
          );

    this.type =
      this.$store.getters.getterDetailChangeManagement.type?.code ?? null;

    this.getChangeManagementType();
  },
  computed: {
    currentTabs() {
      return this.tabs_change_management;
    },

    formattedTypeOptions() {
      return formattedOptions(this.options.types);
    },
  },
  watch: {
    currentTabs(num) {
      // reset state
      if (num == 1) {
        // management item
        this.$store.dispatch(RESET_CHANGE_MANAGEMENT, {
          state: "management-item",
        });
      } else if (num == 2) {
        // design terdampak
        this.$store.dispatch(RESET_CHANGE_MANAGEMENT, {
          state: "design-terdampak",
        });
      } else if (num == 3) {
        // pic
        this.$store.dispatch(RESET_CHANGE_MANAGEMENT, { state: "pic" });
      } else if (num == 4) {
        // file
        this.$store.dispatch(RESET_CHANGE_MANAGEMENT, { state: "file" });
      } else if (num == 5) {
        // response
        this.$store.dispatch(RESET_CHANGE_MANAGEMENT, { state: "response" });
      } else if (num == 6) {
        // response to chief
        this.$store.dispatch(RESET_CHANGE_MANAGEMENT, {
          state: "responseChief",
        });
      }
    },
  },
};
</script>

<style></style>
