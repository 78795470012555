<template>
  <div>
    <div class="text-right mb-3">
      <button class="btn btn-primary mr-3" @click.prevent="load">
        <i class="flaticon2-refresh-button"></i> Load Response
      </button>

      <template v-if="isAdd">
        <template v-if="loadingSave">
          <button class="btn btn-primary mr-3">
            <b-spinner
              variant="light"
              style="height: 1rem; width: 1rem;"
              label="Spinning"
            ></b-spinner>
          </button>
        </template>
      </template>

      <template v-if="activeEdit">
        <button
          class="text-white font-weight-bold mr-3 btn btn-success"
          @click.prevent="showErrorAdd"
        >
          <i class="flaticon-add"></i> Add Data
        </button>
      </template>

      <template v-else>
        <button
          class="text-white font-weight-bold mr-3 btn btn-success"
          @click.prevent="addRowResponse"
        >
          <i class="flaticon-add"></i> Add Data
        </button>
      </template>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="table-responsive">
          <table
            class="table table-head-custom table-vertical-center table-head-bg table-borderless"
          >
            <thead>
              <tr>
                <th style="min-width: 100px;">Scope</th>
                <th style="min-width: 100px;">Response Date</th>
                <th style="min-width: 100px;">File</th>
                <th style="min-width: 100px;" class="text-center">Action</th>
              </tr>
            </thead>
            <template v-if="datas.length == 0 && isEmpty == true">
              <tbody>
                <tr>
                  <template v-if="isLoading">
                    <td colspan="4" class="text-center">
                      <b-spinner variant="primary" label="Spinning"></b-spinner>
                    </td>
                  </template>
                  <template v-else>
                    <td colspan="3">
                      <div class="text-center">
                        <i
                          class="flaticon2-open-box text-secondary icon-3x"
                        ></i>

                        <h4>
                          Data Empty.
                        </h4>
                      </div>
                    </td>
                  </template>
                </tr>
              </tbody>
            </template>
            <template v-if="isLoading && datas.length >= 1 && datas.length < 5">
              <div class="text-center" style="height: 15rem;"></div>
              <tbody>
                <tr v-for="(item, i) in datas" v-bind:key="i">
                  <td>{{ item.scope != null ? item.scope.name : "-" }}</td>
                  <td>
                    <template>
                      <div v-bind:id="'response-change-text-' + i">
                        {{
                          item.date == null
                            ? "-"
                            : moment(item.date).format("DD-MM-YYYY")
                        }}
                      </div>
                      <div
                        class="d-none"
                        v-bind:id="'response-change-form-' + i"
                      >
                        <b-input-group>
                          <input
                            type="text"
                            class="form-control"
                            v-model="edit.date"
                          />
                          <b-input-group-append>
                            <b-form-datepicker
                              button-only
                              right
                              reset-button
                              reset-value=""
                              v-model="edit.date"
                              :no-flip="true"
                            ></b-form-datepicker>
                          </b-input-group-append>
                        </b-input-group>
                      </div>
                    </template>
                  </td>
                  <td>
                    <template>
                      <div v-bind:id="'response-change-text-file-' + i">
                        {{ item.file == null ? "-" : item.file }}
                      </div>
                      <div
                        class="d-none"
                        v-bind:id="'response-change-form-file-' + i"
                      >
                        <b-form-file
                          size="md"
                          v-model="edit.file"
                        ></b-form-file>
                      </div>
                    </template>
                  </td>
                  <td class="text-center">
                    <template>
                      <div v-bind:id="'response-change-edit-' + i">
                        <a
                          class="btn btn-light-success"
                          @click.prevent="editResponseChange(i, item)"
                        >
                          <i class="flaticon-edit"></i>
                        </a>
                        <a
                          class="btn btn-light-danger font-weight-bolder font-size-sm ml-2"
                          @click="openDialogDelete(item)"
                        >
                          <i class="flaticon-delete"></i>
                        </a>
                      </div>
                      <div
                        class="d-none"
                        v-bind:id="'response-change-save-' + i"
                      >
                        <a class="mr-3" @click.prevent="cancel(i)">
                          <i class="flaticon2-cross text-danger"></i>
                        </a>
                        <a
                          class="btn btn-primary"
                          @click.prevent="save(item, i)"
                        >
                          <i class="flaticon2-paperplane text-white"></i>
                        </a>
                      </div>
                    </template>
                  </td>
                </tr>
              </tbody>
            </template>

            <template v-else>
              <tbody>
                <tr v-for="(item, i) in datas" v-bind:key="i">
                  <td>{{ item.scope != null ? item.scope.name : "-" }}</td>
                  <td>
                    <template>
                      <div v-bind:id="'response-change-text-' + i">
                        {{
                          item.date == null
                            ? "-"
                            : moment(item.date).format("DD-MM-YYYY")
                        }}
                      </div>
                      <div
                        class="d-none"
                        v-bind:id="'response-change-form-' + i"
                      >
                        <b-input-group>
                          <input
                            type="text"
                            class="form-control"
                            v-model="edit.date"
                          />
                          <b-input-group-append>
                            <b-form-datepicker
                              button-only
                              right
                              reset-button
                              reset-value=""
                              :no-flip="true"
                              v-model="edit.date"
                            ></b-form-datepicker>
                          </b-input-group-append>
                        </b-input-group>
                      </div>
                    </template>
                  </td>
                  <td>
                    <template>
                      <div v-bind:id="'response-change-text-file-' + i">
                        {{ item.file == null ? "-" : item.file }}
                      </div>
                      <div
                        class="d-none"
                        v-bind:id="'response-change-form-file-' + i"
                      >
                        <b-form-file
                          size="md"
                          v-model="edit.file"
                        ></b-form-file>
                      </div>
                    </template>
                  </td>
                  <td class="text-center">
                    <template>
                      <div v-bind:id="'response-change-edit-' + i">
                        <a
                          class="btn btn-light-success"
                          @click.prevent="editResponseChange(i, item)"
                        >
                          <i class="flaticon-edit"></i>
                        </a>
                        <a
                          class="btn btn-light-danger font-weight-bolder font-size-sm ml-2"
                          @click="openDialogDelete(item)"
                        >
                          <i class="flaticon-delete"></i>
                        </a>
                      </div>
                      <div
                        class="d-none"
                        v-bind:id="'response-change-save-' + i"
                      >
                        <a class="mr-3" @click.prevent="cancel(i)">
                          <i class="flaticon2-cross text-danger"></i>
                        </a>
                        <a
                          class="btn btn-primary"
                          @click.prevent="save(item, i)"
                        >
                          <i class="flaticon2-paperplane text-white"></i>
                        </a>
                      </div>
                    </template>
                  </td>
                </tr>
              </tbody>
            </template>
            <template>
              <tbody
                v-bind:class="{
                  'd-none': addResponse,
                }"
              >
                <tr>
                  <td>
                    <template>
                      <b-form-select v-model="add.scope" class="mb-3">
                        <b-form-select-option
                          v-for="(scope, i) in scopes"
                          v-bind:key="i"
                          :value="scope.code"
                        >
                          {{ scope.name }}
                        </b-form-select-option>
                      </b-form-select>
                    </template>
                  </td>
                  <td>
                    <b-input-group>
                      <input
                        class="form-control"
                        v-model="add.date"
                        type="text"
                        placeholder="YYYY-MM-DD"
                      />
                      <b-input-group-append>
                        <b-form-datepicker
                          button-only
                          right
                          reset-button
                          reset-value=""
                          :no-flip="true"
                          v-model="add.date"
                        ></b-form-datepicker>
                      </b-input-group-append>
                    </b-input-group>
                  </td>
                  <td>
                    <div>
                      <b-form-file size="md" v-model="add.file"></b-form-file>
                    </div>
                  </td>
                  <td class="text-center">
                    <template>
                      <div>
                        <a class="btn" @click.prevent="cancelAddReponse()">
                          <i class="flaticon2-cross text-danger"></i>
                        </a>
                        <a
                          class="btn btn-primary"
                          @click.prevent="saveCreate()"
                        >
                          <i class="flaticon2-paperplane text-white"></i>
                        </a>
                      </div>
                    </template>
                  </td>
                </tr>
              </tbody>
            </template>
          </table>
        </div>
      </div>
    </div>
    <v-dialog
      v-model="dialog"
      transition="dialog-top-transition"
      max-width="400"
    >
      <v-card>
        <v-card-title class="headline">Warning</v-card-title>

        <v-card-text>
          Are You Sure Want To Delete Data
          <b>
            {{
              this.currentDelete == null ? "" : "'" + this.currentDelete + "'"
            }}
          </b>
          ?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="deleteResponse()">
            Delete
          </v-btn>

          <v-btn color="green darken-1" text @click="closeDialog">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  GET_RESPONSE_CHANGE_MANAGEMENT,
  UPDATE_RESPONSE_CHANGE_MANAGEMENT,
  GET_DETAIL_CHANGE,
  ADD_RESPONSE_CHANGE_MANAGEMENT,
  DELETE_RESPONSE,
} from "@/core/services/store/change-management.module";
import ApiService from "@/core/services/api.service";
import { showToast } from "@/core/helper/toast.helper";
import { GET_SCOPES } from "@/core/services/store/scope.module";
import { mapState } from "vuex";
import { arrayMoreThanOne } from "@/core/helper/array-validation.helper";
import { deleteModal } from "../../../../core/helper/toast.helper";
export default {
  name: "Response",
  props: ["project_code"],
  data() {
    return {
      data: {
        response: [
          {
            scope: "HMI",
            date: "28-10-2021",
          },
          {
            scope: "TAN",
            date: "24-05-2021",
          },
        ],
      },
      datas: [],
      edit: {
        date: "",
        file: null,
      },
      add: {
        scope: "",
        date: "",
        file: null,
      },
      options: {
        initial: { text: "loading..." },
        mci: { text: "loading..." },
      },
      selected: {
        response: "",
        list_management: "",
        scope: "",
      },
      currentAddForm: {
        scope: null,
        response_date: null,
        server: null,
        file: null,
      },
      uploadLoading: false,
      showSelectServer: false,
      selectedServerName: null,
      servers: [],
      isLoading: false,
      isEdit: false,
      currentDelete: null,
      addResponse: true,
      isEmpty: true,
      isAdd: false,
      templateEmpty: false,
      activeEdit: false,
      dialogPic: false,
      dialog: false,
      isModalDeletePic: true,
      loadingSave: false,
      isModalDelete: true,
      currentDeleteId: null,
    };
  },
  created() {},
  async mounted() {
    this.$store.dispatch(GET_SCOPES).then((x) => {
      var data = x.data;
      this.selected.scope = data.length > 0 ? data[0].code : "";
    });
    await this.$store.dispatch(GET_DETAIL_CHANGE, {
      id: this.$route.params.id_change_management,
    });
  },
  computed: {
    ...mapState({
      scopes: (state) => state.scope.scopes?.data,
    }),
    formattedScopeOptions() {
      let scopes = [];

      if (arrayMoreThanOne(this.scopes)) {
        scopes = this.scopes.map((scope) => {
          return {
            value: scope.code,
            text: scope.name,
          };
        });
      }

      return [{ value: null, text: "Choose Scope" }, ...scopes];
    },
  },
  methods: {
    async addRowResponse() {
      var check = this.$store.getters.getterResponseChangeManagement;

      if (check == "400" || check.length == 0) {
        this.isEmpty = false;
        this.templateEmpty = true;
        this.addResponse = false;
        this.isAdd = true;
      } else {
        this.isAdd = true;
        this.addResponse = false;
      }
    },

    cancelAddReponse() {
      this.addResponse = true;
      this.activeEdit = false;
      this.isAdd = false;
      this.add.file = null;
      this.add.scope = "";
      this.add.date = null;
      this.isEmpty = true;
    },
    editResponseChange(id, item) {
      this.edit.date = item.date;

      var input = document.getElementById("response-change-form-" + id);
      var text = document.getElementById("response-change-text-" + id);
      var inputFile = document.getElementById(
        "response-change-form-file-" + id
      );
      var textFile = document.getElementById("response-change-text-file-" + id);

      if (this.isEdit == false) {
        input.classList.remove("d-none");
        text.classList.add("d-none");
        inputFile.classList.remove("d-none");
        textFile.classList.add("d-none");

        // button
        var edit = document.getElementById("response-change-edit-" + id);
        var save = document.getElementById("response-change-save-" + id);

        edit.classList.add("d-none");
        save.classList.remove("d-none");
        this.isEdit = true;
      } else {
        this.$bvToast.toast("Selesaikan edit data terlebih dahulu", {
          title: "Warning",
          variant: "warning",
          solid: true,
        });
      }
    },

    async load() {
      this.isLoading = true;
      await this.$store
        .dispatch(GET_RESPONSE_CHANGE_MANAGEMENT, {
          id: this.$route.params.id_change_management,
        })
        .then((x) => {
          if (x == undefined) {
            this.datas = [];
            this.isLoading = false;
          } else {
            this.datas = this.$store.getters.getterResponseChangeManagement;
          }
        });
    },
    async saveCreate() {
      var date = this.add.date;
      if (date == "") {
        this.$bvToast.toast("Date Can't Be Empty", {
          title: "Warning",
          variant: "warning",
          solid: true,
        });
      } else {
        var split = date.split("-");
        var fix = split[0] + "-" + split[1] + "-" + split[2];
        var send = [{ date: fix, scope: this.add.scope }];
        await this.$store
          .dispatch(ADD_RESPONSE_CHANGE_MANAGEMENT, {
            project_code: this.project_code,
            id: this.$route.params.id_change_management,
            send: send,
          })
          .then((x) => {
            if (this.add.file) {
              const formData = new FormData();
              formData.append("id", x.data[0].id);
              formData.append("file", this.add.file);

              ApiService.post(
                `project/change/management/${this.$route.params.id_change_management}/response/upload`,
                formData
              )
                .then(() => {
                  this.add.file = null;

                  this.load();
                })
                .catch(() => {
                  showToast("Failed", "Upload file failed", "danger");
                });
            }
          });

        this.load();
        this.cancelAddReponse();
        this.$bvToast.toast("Response Saved Successfully", {
          title: "Success",
          variant: "success",
          solid: true,
        });
      }
    },

    async save(item, i) {
      var date = this.edit.date;
      if (date == "") {
        this.$bvToast.toast("Date Can't Be Empty", {
          title: "Warning",
          variant: "warning",
          solid: true,
        });
      } else {
        var split = date.split("-");
        var fix = split[2] + "-" + split[1] + "-" + split[0];
        var send = [{ id: item.id, date: fix }];

        await this.$store.dispatch(UPDATE_RESPONSE_CHANGE_MANAGEMENT, {
          project_code: this.project_code,
          id: this.$route.params.id_change_management,
          send: send,
        });

        if (this.edit.file) {
          const formData = new FormData();

          formData.append("id", send[0].id);

          formData.append("file", this.edit.file);

          ApiService.post(
            `project/change/management/${this.$route.params.id_change_management}/response/upload`,
            formData
          )
            .then(() => {
              this.edit.file = null;

              this.load();
            })
            .catch(() => {
              showToast("Failed", "Upload file failed", "danger");
            });
        }

        this.load();
        this.cancel(i);
        this.$bvToast.toast("Response Saved Successfully", {
          title: "Success",
          variant: "success",
          solid: true,
        });
      }
    },

    cancel(id) {
      var input = document.getElementById("response-change-form-" + id);
      var text = document.getElementById("response-change-text-" + id);
      var inputFile = document.getElementById(
        "response-change-form-file-" + id
      );
      var textFile = document.getElementById("response-change-text-file-" + id);

      input.classList.add("d-none");
      inputFile.classList.add("d-none");
      text.classList.remove("d-none");
      textFile.classList.remove("d-none");

      // button
      var edit = document.getElementById("response-change-edit-" + id);
      var save = document.getElementById("response-change-save-" + id);

      edit.classList.remove("d-none");
      save.classList.add("d-none");
      this.isEdit = false;
    },
    openDialogDelete(item) {
      // this.currentDelete = item.dld.title;
      this.currentDeleteId = item.id;
      this.deleteResponse();
    },
    closeDialog() {
      this.dialog = false;
      this.isModalDelete = true;
      this.currentDelete = null;
      this.currentDeleteId = null;
    },
    async deleteResponse() {
      const deleteFunction = async () => {
        this.dialog = false;
        this.isModalDelete = true;
        var id = this.currentDeleteId;
        await this.$store.dispatch(DELETE_RESPONSE, { id: id });
        this.$bvToast.toast("Data Deleted Successfully", {
          title: "Success",
          variant: "success",
          solid: true,
        });
        this.load();
      };

      deleteModal(deleteFunction);
    },
  },
};
</script>
