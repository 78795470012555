<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <b-row>
                  <b-col sm="5">
                    <label for="input-small">Project Code</label>
                  </b-col>
                  <b-col sm="7">
                    <b-form-input
                      id="input-small"
                      size="md"
                      disabled
                      v-model="currentSelection.code"
                    ></b-form-input>
                  </b-col>
                </b-row>
              </div>
              <div class="col-6">
                <b-row>
                  <b-col sm="5">
                    <label for="input-small">Owner</label>
                  </b-col>
                  <b-col sm="7">
                    <b-form-input
                      id="input-small"
                      size="md"
                      disabled
                      v-model="currentSelection.owner"
                    ></b-form-input>
                  </b-col>
                </b-row>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-6">
                <b-row>
                  <b-col sm="5">
                    <label for="input-small">Project Name</label>
                  </b-col>
                  <b-col sm="7">
                    <b-form-input
                      id="input-small"
                      size="md"
                      disabled
                      v-model="currentSelection.name"
                    ></b-form-input>
                  </b-col>
                </b-row>
              </div>
              <div class="col-6">
                <b-row>
                  <b-col sm="5">
                    <label for="input-small">Contract</label>
                  </b-col>
                  <b-col sm="7">
                    <b-form-input
                      id="input-small"
                      size="md"
                      disabled
                      v-model="currentSelection.contract_no"
                    ></b-form-input>
                  </b-col>
                </b-row>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-6">
                <b-row>
                  <b-col sm="5">
                    <label for="input-small">Status</label>
                  </b-col>
                  <b-col sm="7">
                    <b-form-input
                      id="input-small"
                      size="md"
                      disabled
                      v-model="currentSelection.status"
                    ></b-form-input>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <div class="row mt-4">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div>
              <b-tabs content-class="mt-3" v-model="tabindex">
                <b-tab title="Document" active>
                  <document :selection="currentSelection"></document>
                </b-tab>
                <b-tab title="Drawing">
                  <drawing></drawing>
                </b-tab>
                <b-tab title="Software">
                  <software></software>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Document from "./add-design/Document";
import Drawing from "./add-design/Drawing";
import Software from "./add-design/Software";
import { RESET_DESIGN_TERDAMPAK } from "@/core/services/store/change-management.module";

export default {
  name: "AddDesignTerdampak",
  components: {
    Document,
    Drawing,
    Software
  },
  data() {
    return {
      mantap: this.$store.getters.getterDetailChangeManagement,
      currentSelection: {
        code: this.$store.getters.getterDetailChangeManagement.project.code,
        owner: "",
        name: this.$store.getters.getterDetailChangeManagement.project.name,
        contract_no: "",
        status: this.$store.getters.getterDetailChangeManagement.project.status
      },
      tabindex: 0
    };
  },
  methods: {},
  computed: {
    currentTab() {
      return this.tabindex;
    }
  },
  watch: {
    currentTab() {
      this.$store.dispatch(RESET_DESIGN_TERDAMPAK);
    }
  }
};
</script>
