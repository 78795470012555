<template>
  <div>
    <div class="text-right mb-3">
      <button class="btn btn-primary mr-3" @click.prevent="load">
        <i class="flaticon2-refresh-button"></i> Load PIC
      </button>

      <template v-if="isAdd">
        <template v-if="loadingSave">
          <button class="btn btn-primary mr-3">
            <b-spinner
              variant="light"
              style="height: 1rem; width: 1rem;"
              label="Spinning"
            ></b-spinner>
          </button>
        </template>
      </template>

      <template v-if="activeEdit">
        <button
          class="text-white font-weight-bold mr-3 btn btn-success"
          @click.prevent="showErrorAdd"
        >
          <i class="flaticon-add"></i> Add Data
        </button>
      </template>

      <template v-else>
        <button
          class="text-white font-weight-bold mr-3 btn btn-success"
          @click.prevent="addRowPic"
        >
          <i class="flaticon-add"></i> Add Data
        </button>
      </template>
    </div>

    <div class="table-responsiv">
      <table
        class="table table-head-custom table-vertical-center table-head-bg table-borderless"
      >
        <thead>
          <tr>
            <th style="min-width: 40px">Initial</th>
            <th>Name</th>
            <th>Scope</th>
            <th class="text-center">Action</th>
          </tr>
        </thead>
        <template
          v-if="
            this.$store.getters.getterPicManagement.length == 0 ||
              this.$store.getters.getterPicManagement == '400'
          "
        >
          <tbody>
            <tr
              v-if="isLoading"
              v-bind:class="{
                'd-none': templateEmpty
              }"
            >
              <td colspan="4">
                <div class="text-center">
                  <i class="flaticon2-open-box text-secondary icon-3x"></i>

                  <template v-if="isEmpty">
                    <h4>
                      Data Not Found.
                    </h4>
                  </template>
                  <template v-else>
                    <h4>
                      Data Empty.
                    </h4>
                  </template>
                </div>
              </td>
            </tr>
            <tr v-else>
              <td class="text-center" colspan="4">
                <b-spinner variant="primary" label="Spinning"></b-spinner>
              </td>
            </tr>
          </tbody>
        </template>
        <template v-else>
          <tbody>
            <template
              v-for="(item, i) in this.$store.getters.getterPicManagement"
            >
              <tr v-bind:key="i">
                <td>
                  <div>
                    {{ item.pic != null ? item.pic.code : "-" }}
                  </div>
                </td>
                <td>
                  <template>
                    <div v-bind:class="'d-none pic-change-form-' + item.id">
                      <model-select
                        class="mb-3"
                        :options="formatPicValues(item)"
                        v-model="selected.pic"
                      ></model-select>
                    </div>
                  </template>
                  <div v-bind:class="'pic-change-text-' + item.id">
                    {{ item.pic != null ? item.pic.name : "-" }}
                  </div>
                </td>
                <td>
                  <template>
                    <div v-bind:class="'pic-change-text-' + item.id">
                      {{ item.scope != null ? item.scope.name : "-" }}
                    </div>

                    <div v-bind:class="'d-none pic-change-form-' + item.id">
                      <b-form-select v-model="selected.scope" class="mb-3">
                        <b-form-select-option
                          v-for="(scope, i) in scopes"
                          v-bind:key="i"
                          :value="
                            scope.code +
                              '/' +
                              scope.name +
                              `/${item.id}/${item.id}`
                          "
                        >
                          {{ scope.name }}
                        </b-form-select-option>
                      </b-form-select>
                    </div>
                  </template>
                </td>
                <td class="text-center">
                  <template>
                    <div v-bind:id="'pic-change-button-' + item.id">
                      <a
                        class="btn btn-light-success font-weight-bolder font-size-sm"
                        @click.prevent="editPicChange(item.id, item.id, item)"
                      >
                        <i class="flaticon-edit"></i>
                      </a>

                      <template>
                        <a
                          class="btn btn-light-danger d-none font-weight-bolder font-size-sm"
                          v-bind:id="'loading-delete-' + item.id"
                        >
                          <b-spinner
                            variant="light"
                            style="width: 1rem; height: 1rem;"
                            label="Spinning"
                          ></b-spinner>
                        </a>
                      </template>
                      <template>
                        <a
                          class="btn btn-light-danger font-weight-bolder font-size-sm ml-2"
                          @click.prevent="deletePic(item)"
                          v-bind:id="'delete-text-' + item.id"
                        >
                          <i class="flaticon-delete"></i>
                        </a>
                      </template>
                    </div>
                    <div
                      class="d-none"
                      v-bind:id="'pic-change-cancel-' + item.id"
                    >
                      <a
                        class="btn btn-light-danger font-weight-bolder font-size-sm"
                        @click.prevent="cancelEdit(item.id)"
                      >
                        <i class="flaticon2-cross"></i>
                      </a>
                    </div>
                  </template>
                </td>
              </tr>
            </template>
          </tbody>
        </template>
        <template>
          <tbody
            v-bind:class="{
              'd-none': addPic
            }"
          >
            <tr>
              <td>
                -
              </td>
              <td>
                <model-select
                  class="mb-3"
                  :options="formatPicValues(null)"
                  v-model="selected.pic"
                ></model-select>
              </td>
              <td>
                <template>
                  <b-form-select v-model="selected.scope" class="mb-3">
                    <b-form-select-option
                      v-for="(scope, i) in scopes"
                      v-bind:key="i"
                      :value="scope.code + '/' + scope.name + '/0/0'"
                    >
                      {{ scope.name }}
                    </b-form-select-option>
                  </b-form-select>
                </template>
              </td>
              <td class="text-center">
                <a class="btn btn-light-danger" @click.prevent="cancelAddPic">
                  <i class="flaticon2-cross"></i>
                </a>
              </td>
            </tr>
          </tbody>
        </template>
      </table>
    </div>

    <div class="d-flex justify-content-end">
      <template v-if="!loadingSave && isAdd">
        <button class="btn btn-success mr-3" @click.prevent="save()">
          <i class="flaticon2-check-mark"></i> Save Change PIC
        </button>
      </template>
    </div>

    <v-app
      v-bind:class="{
        'd-none': isModalDeletePic
      }"
    >
      <v-dialog
        v-model="dialogPic"
        transition="dialog-top-transition"
        max-width="400"
      >
        <v-card>
          <v-card-title class="headline">Warning</v-card-title>

          <v-card-text>
            Are You Sure Want To delete Data
            <b>
              {{
                this.currentDelete == null ? "" : "'" + this.currentDelete + "'"
              }}
            </b>
            ?
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="green darken-1" text @click="deleteDesign()">
              Delete
            </v-btn>

            <v-btn color="green darken-1" text @click="closeDialog">
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-app>
  </div>
</template>

<script>
import {
  GET_PIC_CHANGE,
  GET_PIC_CHANGE_MANAGEMENT,
  // GET_LIST_MANAGEMENT_ITEM,
  SAVE_PIC_CHANGE_MANAGEMENT,
  DELETE_PIC_CHANGE_MANAGEMENT
} from "@/core/services/store/change-management.module";
import { GET_PIC } from "@/core/services/store/pic.module";
import { mapState } from "vuex";
import { arrayMoreThanOne } from "@/core/helper/array-validation.helper";
import { GET_SCOPES } from "@/core/services/store/scope.module";
import { ModelSelect } from "vue-search-select";
import { deleteModal } from "../../../../core/helper/toast.helper";

export default {
  name: "Pic",
  components: {
    ModelSelect
  },
  data() {
    return {
      data: {
        pic: [
          {
            initial: "RDW",
            name: "Ridwan",
            cmi: "Item 1"
          },
          {
            initial: "ARS",
            name: "Aris",
            cmi: "Item 2"
          },
          {
            initial: "IMG",
            name: "Ilham",
            cmi: "Item 3"
          }
        ]
      },
      options: {
        initial: { text: "loading..." },
        mci: { text: "loading..." }
      },
      selected: {
        pic: "",
        list_management: "",
        scope: ""
      },
      currentDelete: null,
      addPic: true,
      isEmpty: false,
      isLoading: true,
      isAdd: false,
      templateEmpty: false,
      activeEdit: false,
      dialogPic: false,
      isModalDeletePic: true,
      loadingSave: false
    };
  },
  methods: {
    async addRowPic() {
      var check = this.$store.getters.getterPicManagement;

      if (check == "400" || check.length == 0) {
        this.isEmpty = false;
        this.isLoading = true;
        this.templateEmpty = true;
        this.addPic = false;
        this.isAdd = true;
      } else {
        this.isAdd = true;
        this.addPic = false;
      }

      // await this.$store.dispatch(GET_LIST_MANAGEMENT_ITEM, {
      //   id: this.$route.params.id_change_management
      // });
      // await this.$store.dispatch(GET_PIC_CHANGE);
    },

    cancelAddPic() {
      this.addPic = true;
      this.activeEdit = false;
      this.isAdd = false;
      this.selected.pic = "";
      this.selected.scope = "";
    },

    async editPicChange(idItem, id, item) {
      var active = this.addPic;

      this.selected.pic =
        item.pic.code + "/" + item.pic.name + "/" + item.id + "/" + item.id;

      this.selected.scope =
        item.scope.code + "/" + item.scope.name + "/" + item.id + "/" + item.id;

      if (active == false) {
        this.$bvToast.toast("Please Finish Add Data First", {
          title: "Warning",
          variant: "danger",
          solid: true
        });
      } else {
        if (this.activeEdit == true) {
          this.$bvToast.toast("Please Finisih Update Data First", {
            title: "Warning",
            variant: "danger",
            solid: true
          });
        } else {
          this.activeEdit = true;
          this.isAdd = true;
          var input = document.getElementsByClassName("pic-change-form-" + id);
          var text = document.getElementsByClassName("pic-change-text-" + id);

          for (var i = 0; i < input.length; i++) {
            input[i].classList.remove("d-none");
            text[i].classList.add("d-none");
          }

          // button
          var action = document.getElementById("pic-change-button-" + id);
          var cancel = document.getElementById("pic-change-cancel-" + id);

          action.classList.add("d-none");
          cancel.classList.remove("d-none");

          var option = [];
          await this.$store.dispatch(GET_PIC_CHANGE);
          this.options.initial = "";
          var picOption = this.$store.getters.getterPicChangeMangement;
          for (var p = 0; p < picOption.length; p++) {
            option.push({
              value:
                picOption[p].code +
                "/" +
                picOption[p].name +
                "/" +
                id +
                "/" +
                idItem,
              text: picOption[p].name
            });
          }

          this.options.initial = option;

          var ops = [];
          // await this.$store.dispatch(GET_LIST_MANAGEMENT_ITEM, {
          //   id: this.$route.params.id_change_management
          // });
          this.options.mci = "";
          var listOption = this.$store.getters.getterManagementItem;
          for (var x = 0; x < listOption.length; x++) {
            ops.push({
              value:
                listOption[x].code +
                "/" +
                listOption[x].type.code +
                "/" +
                listOption[x].type.name +
                "/" +
                listOption[x].issue,
              text: listOption[x].code + " - " + listOption[x].issue
            });
          }

          this.options.mci = ops;
        }
      }
    },

    async load() {
      this.isLoading = false;
      await this.$store.dispatch(GET_PIC_CHANGE_MANAGEMENT, {
        id: this.$route.params.id_change_management
      });
      this.isLoading = true;

      if (this.$store.getters.getterPicManagement == "400") {
        this.isLoading = true;
        this.isEmpty = true;
      } else {
        this.isLoading = true;
        this.isEmpty = false;
      }
    },

    async save() {
      var pic = this.selected.pic;

      var scope = this.selected.scope;

      if (pic == "" || scope == "") {
        this.$bvToast.toast("All Field Can't Be Empty", {
          title: "Warning",
          variant: "danger",
          solid: true
        });
      } else {
        var picSplit = pic.split("/");

        var scopeSplit = scope.split("/");

        var id = picSplit[2];

        var send;

        if (id != 0) {
          var idItem = picSplit[3];

          send = [
            {
              id: idItem,
              pic: {
                code: picSplit[0],
                name: picSplit[1]
              },
              scope: {
                code: scopeSplit[0],
                name: scopeSplit[1]
              },
              change_management_item: null
            }
          ];
        } else {
          send = [
            {
              id: null,
              pic: {
                code: picSplit[0],
                name: picSplit[1]
              },
              scope: {
                code: scopeSplit[0],
                name: scopeSplit[1]
              },
              change_management_item: null
            }
          ];
        }

        this.loadingSave = true;
        await this.$store.dispatch(SAVE_PIC_CHANGE_MANAGEMENT, {
          id: this.$route.params.id_change_management,
          send: send
        });
        this.loadingSave = false;

        if (this.$store.getters.getterPicManagement.length == 0) {
          this.load();
          this.$bvToast.toast("PIC Saved Successfully", {
            title: "Success",
            variant: "success",
            solid: true
          });
          this.addPic = true;
          this.selected.pic = "";
          this.selected.list_management = "";
          this.isAdd = false;
          this.activeEdit = false;
        } else {
          try {
            var input = document.getElementsByClassName(
              "pic-change-form-" + id
            );
            var text = document.getElementsByClassName("pic-change-text-" + id);

            for (var i = 0; i < input.length; i++) {
              input[i].classList.add("d-none");
              text[i].classList.remove("d-none");
            }

            // button
            var action = document.getElementById("pic-change-button-" + id);
            var cancel = document.getElementById("pic-change-cancel-" + id);

            action.classList.remove("d-none");
            cancel.classList.add("d-none");
          } catch (error) {
            console.log(error);
          }

          this.load();
          this.$bvToast.toast("PIC Saved Successfully", {
            title: "Success",
            variant: "success",
            solid: true
          });
          this.addPic = true;
          this.selected.pic = "";
          this.selected.list_management = "";
          this.isAdd = false;
          this.activeEdit = false;
        }
      }
    },

    cancelEdit(id) {
      this.selected.pic = "";
      this.selected.scope = "";

      var input = document.getElementsByClassName("pic-change-form-" + id);
      var text = document.getElementsByClassName("pic-change-text-" + id);

      for (var i = 0; i < input.length; i++) {
        input[i].classList.add("d-none");
        text[i].classList.remove("d-none");
      }

      // button
      var action = document.getElementById("pic-change-button-" + id);
      var cancel = document.getElementById("pic-change-cancel-" + id);

      action.classList.remove("d-none");
      cancel.classList.add("d-none");

      this.options.initial = { text: "loading..." };
      this.options.mci = { text: "loading..." };
      this.activeEdit = false;
      this.isAdd = false;
    },

    showErrorAdd() {
      this.$bvToast.toast("Please Finish Edit Data First", {
        title: "Warning",
        variant: "danger",
        solid: true
      });
    },

    showModalDelete(item) {
      this.dialogPic = true;
      this.isModalDeletePic = false;
      this.currentDelete = item;
    },

    closeDialog() {
      this.isModalDeletePic = true;
      this.dialogPic = false;
      this.currentDelete = null;
    },

    async deletePic(item) {
      const deleteFunction = async () => {
        var id = item.id;
        var id_change = this.$route.params.id_change_management;

        var loading = document.getElementById("loading-delete-" + item.id);
        var text = document.getElementById("delete-text-" + item.id);

        loading.classList.remove("d-none");
        text.classList.add("d-none");
        await this.$store.dispatch(DELETE_PIC_CHANGE_MANAGEMENT, {
          id: id,
          id_change_management: id_change
        });
        loading.classList.add("d-none");
        text.classList.remove("d-none");

        this.loadingDelete = false;
        this.$bvToast.toast("Data Deleted Successfully", {
          title: "Success",
          variant: "success",
          solid: true
        });
      };

      deleteModal(deleteFunction);
    },

    formatPicValues(item) {
      return (
        this.pics?.map(pic => {
          return {
            text: pic.name,
            value:
              pic.code +
              "/" +
              pic.name +
              `/${item ? item.id : 0}/${item ? item.id : 0}`
          };
        }) ?? []
      );
    }
  },
  mounted() {
    this.$store.dispatch(GET_PIC);

    this.$store.dispatch(GET_SCOPES);
  },
  computed: {
    ...mapState({
      pics: state => state.pic.pics?.data,
      scopes: state => state.scope.scopes?.data
    }),

    formattedPicOptions() {
      let pics = [];

      if (arrayMoreThanOne(this.pics)) {
        pics = this.pics.map(pic => {
          return {
            value: pic.code,
            text: pic.name
          };
        });
      }

      return [{ value: null, text: "Choose PIC" }, ...pics];
    },

    formattedScopeOptions() {
      let scopes = [];

      if (arrayMoreThanOne(this.scopes)) {
        scopes = this.scopes.map(scope => {
          return {
            value: scope.code,
            text: scope.name
          };
        });
      }

      return [{ value: null, text: "Choose Scope" }, ...scopes];
    }
  }
};
</script>
